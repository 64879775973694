import React from 'react';
import { Link } from 'react-router-dom';
import { FaMeetup } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-cover bg-repeat bg-center text-white pt-10 lg:pt-100  mt-20 lg:px-20">
      <div className="container mx-auto flex lg:justify-between gap-10 flex-col lg:flex-row text-lg min-h-200">
        {/* Social Media Section */}
        <div className="flex flex-col">
          <p
            className="text-white text-2xl mb-4"
            style={{ fontFamily: "'Old Standard TT', serif" }}
          >
            Follow Us
          </p>
          <div className="flex gap-3">
            <Link
              className="h-10 w-10 text-white bg-gray-700 flex items-center justify-center"
              to={{ pathname: 'https://www.facebook.com/mrara.enterprises' }}
              target="_blank"
            >
              <i
                className="fab fa-facebook-f cursor-pointer"
                aria-hidden="true"
              />
            </Link>
            <Link
              className="h-10 w-10 text-white bg-gray-700 flex items-center justify-center"
              to={{ pathname: 'https://www.instagram.com/mrara_enterprises' }}
              target="_blank"
            >
              <i className="fab fa-instagram" aria-hidden="true"></i>
            </Link>
          </div>
        </div>
        {/* Contact Info Section */}
        <div className="">
          <p
            className="text-white text-2xl mb-4"
            style={{ fontFamily: "'Old Standard TT', serif" }}
          >
            Contact Us
          </p>
          <div>
            <div className="flex gap-4">
              <div className="text-2xl lg:text-4xl">
                <i className="fa fa-phone-square" aria-hidden="true"></i>
              </div>
              <div>
                <p
                  className="text-white text-sm"
                  style={{ fontFamily: "'Old Standard TT', serif" }}
                >
                  Phone Number
                </p>
                <p className="text-xs">+1 (469) 459-3574</p>
              </div>
            </div>
            <div className="flex gap-4 mt-6">
              <div className="text-2xl lg:text-4xl">
                <FaMeetup />
              </div>
              <div>
                <a
                  href="https://calendly.com/mhtemnewo/mrara-car-rental-meeting"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p
                    className="text-sm"
                    style={{ fontFamily: "'Old Standard TT', serif" }}
                  >
                    Schedule a Meeting
                  </p>
                  <p className="text-xs">Calendly</p>
                </a>
              </div>
            </div>
            <div className="flex gap-4 mt-6">
              <div className="text-2xl lg:text-4xl">
                {/* <i className="flaticon-message" /> */}
                <i class="fa fa-envelope" aria-hidden="true"></i>
              </div>
              <div>
                <p
                  className="text-white text-sm"
                  style={{ fontFamily: "'Old Standard TT', serif" }}
                >
                  Email Address
                </p>
                <p className="text-xs">mraraenterprises@gmail.com</p>
              </div>
            </div>
            <div className="flex gap-4 mt-6">
              <div className="text-2xl lg:text-4xl">
                {/* <i className="flaticon-location-pin" /> */}
                <i class="fa fa-map-marker" aria-hidden="true"></i>
              </div>
              <div>
                <p
                  className="text-white text-sm"
                  style={{ fontFamily: "'Old Standard TT', serif" }}
                >
                  Office Address
                </p>
                <p className="text-xs">
                  5000 Eldorado pkwy suite 150-405 Frisco TX 75033
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Services Section */}
        <div>
          <p
            className="text-white text-2xl mb-4"
            style={{ fontFamily: "'Old Standard TT', serif" }}
          >
            Services
          </p>
          <div className="flex gap-2">
            <div className="text-xl">
              {/* <i className="flaticon-call" /> */}
              <i class="fa fa-car" aria-hidden="true"></i>
            </div>
            <div>
              <Link
                className="text-white "
                style={{ fontFamily: "'Old Standard TT', serif" }}
                to="/"
                // to="/search_results"
              >
                Car Rental
              </Link>
            </div>
          </div>
        </div>
        {/* Legal Section */}
        <div>
          <p
            className="text-white text-2xl mb-4"
            style={{ fontFamily: "'Old Standard TT', serif" }}
          >
            Legal
          </p>
          <div>
            <Link
              className="text-white text-sm"
              style={{ fontFamily: "'Old Standard TT', serif" }}
              to="/terms_of_use"
            >
              Terms of use
            </Link>
          </div>
          <div>
            <Link
              className="text-white text-sm"
              style={{ fontFamily: "'Old Standard TT', serif" }}
              to="/privacy_policy"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
      <p className="text-center mt-16 mb-2 text-sm">
        &copy; {new Date().getFullYear()} Mrara Enterprises
      </p>
    </footer>
  );
};

export default Footer;

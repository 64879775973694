import React, { Fragment, Suspense } from 'react';

import { Route, Switch } from 'react-router-dom';
// import Header from './components/Header';
import Home from './pages/Home';
import Login from './pages/Login';
import Footer from './components/Footer';
import AnonymousGuard from '../shared/auth/AnonymousGuard';
import SignUpForm from './components/SignUpForm';
import SearchResults from './pages/SearchResults';
import CarDetail from './pages/CarDetail';
import PasswordResetRequest from './pages/PasswordResetRequest';
import PasswordReset from './pages/PasswordReset';
import GoogleAuth from './components/GoogleAuth';
import VerifyAccount from './pages/VerifyAccount';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CarReservationSuccess from './pages/CarReservationSuccess';
import useScrollToTop from '../shared/hooks/useScrollToTop';

function Anonymous() {
  useScrollToTop();

  return (
    <Fragment>
      {/* <Header phoneNumber="+1 832 861 6479" /> */}
      <Suspense fallback={<div></div>}>
        <div className="flex-1 h-full mt-24">
          <Switch>
            <Route exact path="/" component={SearchResults} />
            <AnonymousGuard exact path="/login" component={Login} />
            <AnonymousGuard
              exact
              path="/password_reset_request"
              component={PasswordResetRequest}
            />
            <AnonymousGuard
              exact
              path="/reset_password"
              component={PasswordReset}
            />
            <AnonymousGuard exact path="/sign_up" component={SignUpForm} />
            <Route
              exact
              path="/car_reservation/success/:id"
              component={CarReservationSuccess}
            />
            <Route exact path="/search_results" component={SearchResults} />
            <Route exact path="/car_detail/:id" component={CarDetail} />
            <Route exact path="/google_auth" component={GoogleAuth} />
            <Route
              exact
              path="/authenticate/verify_email"
              component={VerifyAccount}
            />
            <Route exact path="/terms_of_use" component={TermsOfUse} />
            <Route exact path="/privacy_policy" component={PrivacyPolicy} />
          </Switch>
        </div>
      </Suspense>
      <Footer />
    </Fragment>
  );
}

Anonymous.propTypes = {};

export default Anonymous;
